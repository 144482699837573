<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, name, source, sourceLanguage]
    x-vue-custom-fields: [ ]
    x-vue-tags: [ ]
    x-vue-metadata: [ ]
    -->
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="transformers"
  />
</template>
<script>
  import { transformers } from '@/config/descriptions/transformers';
  import { transformersTexts } from '@/config/pageTexts/transformers.json';

  export default {
    name: 'TransformersTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'transformers/transformers',
            fields: 'transformers/fields',
            sortableFields: 'transformers/sortableFields',
            pages: 'transformers/pages',
            loading: 'transformers/loading',
            resultCount: 'transformers/resultCount',
            totalRecords: 'transformers/totalRecords',
            originalFields: 'transformers/originalFields',
            searchableAllFields: 'transformers/searchableAllFields',
            success: 'transformers/success',
            message: 'transformers/message',
          },
          actions: {
            fields: 'transformers/handleFields',
            delete: 'transformers/handleDeleteTransformers',
            reset: 'transformers/handleClearFilter',
            query: 'transformers/handleGetTransformersByQuery',
          },
        },
        settings: {
          describe: { ...transformers },
          page: { ...transformersTexts },
        },
        route: {
          create: 'CreateTransformer',
          edit: 'EditTransformer',
          preview: 'PreviewTransformer',
        },
      };
    },
  };
</script>