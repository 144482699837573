<template>
  <TransformersTable/>
</template>
<script>
import TransformersTable from '@/generated/ziqni/views/transformers/TransformersTable';

export default {
  components: {
    TransformersTable
  },
  props: {}
};
</script>